.contact {
  background-color: #efefef;
  color: black;
  padding: 50px calc((100vw - 960px)/2);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  h1, h6 {
    margin: 0;
  }

  .logo {
    width: 70px;
    height: 55px;
    flex: 1;
  }

  >* {
    margin: 1.2em;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h6{
      margin: 0;
      margin-bottom: 0.6em;
    }
  }

  address {
    p {
      margin: 0;
      // line-height: 1.44em;
      font-size: 0.7em;
      font-style: normal;
      color: #666666;
    }
  }

  .related-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      // line-height: 1em;
    }
    a {
      text-decoration: none;
      font-size: 0.7em;
      color: #666666;
      &:hover {
        color: $main-color;
      }
    }
  }

  .contact-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      margin: 4px;

      &:first-child {
        margin-left: 0;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .contact {
    padding: 50px;
  }
}

@media screen and (max-width: 720px) {
  .contact {
    padding: 60px;

    .logo {
      flex: 0 0 100%;
    }
  }
}