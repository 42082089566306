.intro {
  height: 100vh;
  background: url('/public/images/hacker.jpeg') no-repeat center center;
  background-size: cover;

  .logo {
    position: absolute;
    top: 20px;
    left: calc(50vw - 45px);
  }

  .title {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    color: white;
  
    h1 {
      margin: calc(-6px - 1vw) 0 calc(-2px - 1vw) 0;
      font-size: calc(30px + 3.5vw);
    }
    
    p {
      font-size: calc(8px + 1vw);
      font-weight: 300;
      margin: 0;
      text-align: center;
    }
    
    .line  {
      align-self: center;
      width: 100%;
      text-overflow: clip;
      white-space: nowrap;
      overflow: hidden;
      background-color: $main-color;
      &:first-of-type {
        margin-bottom: 0.5vw;
      }
    }

    .linep {
      text-align: center;
    }

    .clipper {
      overflow: hidden;
      display: block;
    }
  }
}